define("projektitekt/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NVixk73f",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[1,0,0,0,[31,0,0,[27,[26,1,\"CallHead\"],[]],[\"Projektitekt\"],[[\"separator\",\"prepend\",\"_deprecate\"],[\" · \",false,\"/tmp/broccoli-2022mBFeJ3bfqekG/out-267-colocated_template_processor/projektitekt/templates/application.hbs\"]]]],[1,1,0,0,\"\\n\"],[1,0,0,0,[31,153,24,[27,[26,2,\"CallHead\"],[]],null,[[\"key\"],[[27,[24,0],[\"router\",\"currentRouteName\"]]]]]],[1,1,0,0,\"\\n\\n\"],[7,\"page-progress\",[],[[\"@background\"],[\"white\"]],null],[1,1,0,0,\"\\n\"],[7,\"header\",[],[[],[]],null],[1,1,0,0,\"\\n\\n\"],[1,0,0,0,[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\\n\"],[7,\"footer\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[\"head-layout\",\"page-title\",\"remember-document-scroll\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "projektitekt/templates/application.hbs"
    }
  });

  _exports.default = _default;
});