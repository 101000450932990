define("projektitekt/templates/akademie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "34Vb98Kr",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,0,0,0,[31,0,0,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"title\"]]],[[\"_deprecate\"],[\"/tmp/broccoli-2022mBFeJ3bfqekG/out-267-colocated_template_processor/projektitekt/templates/akademie.hbs\"]]]],[1,1,0,0,\"\\n\\n\"],[9,\"h1\",true],[12,\"class\",\"title\",null],[10],[1,0,0,0,[27,[24,1],[\"title\"]]],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"content\",null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,91,4,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[\"html\"]]],null]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page-title\",\"html\"]}",
    "meta": {
      "moduleName": "projektitekt/templates/akademie.hbs"
    }
  });

  _exports.default = _default;
});